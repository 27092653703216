import {
	Button,
	Card,
	Col,
	Divider,
	Form,
	InputNumber,
	Row,
	Table,
} from "antd";
import { useState } from "react";

const PositionSizedCalculator = () => {
	const [datasource, setDatasource] = useState([]);

	const handleFinish = (values: any) => {
		console.log(values);
		const { entry, risk_reward_ratio, stop_loss, risk } = values;

		const take_profit = calculateTakeProfit(
			risk_reward_ratio,
			entry,
			stop_loss
		);

		const qty = calculateQuantity(risk, entry, stop_loss);

		setDatasource([
			{
				entry,
				stop_loss,
				risk,
				qty,
				take_profit,
				risk_reward_ratio,
				profit: calculateProfit(take_profit, entry, qty),
			},
		]);
	};
	return (
		<Row gutter={[12, 12]} style={{ width: "100%" }}>
			<Col span={24}>
				<Card>
					<Form
						requiredMark="optional"
						colon={false}
						labelCol={{ span: 12 }}
						labelAlign="left"
						onFinish={handleFinish}
					>
						<Form.Item
							label="Entry"
							name={"entry"}
							rules={[{ required: true }]}
						>
							<InputNumber style={{ width: "100%" }} />
						</Form.Item>
						<Form.Item
							label="Risk-Reward Ratio"
							name={"risk_reward_ratio"}
							rules={[{ required: true }]}
						>
							<InputNumber style={{ width: "100%" }} />
						</Form.Item>
						<Form.Item
							label="Stop Loss"
							name={"stop_loss"}
							rules={[{ required: true }]}
						>
							<InputNumber style={{ width: "100%" }} />
						</Form.Item>
						<Form.Item label="Risk" name={"risk"} rules={[{ required: true }]}>
							<InputNumber addonBefore="$" style={{ width: "100%" }} />
						</Form.Item>
						<Divider />
						<Form.Item>
							<Button htmlType="submit" type="primary">
								Apply
							</Button>
						</Form.Item>
					</Form>
				</Card>
			</Col>
			<Col span={24}>
				<Table
					tableLayout="fixed"
					size="small"
					bordered
					dataSource={datasource}
					columns={[
						{ dataIndex: "risk", title: "Risk ($)" },
						{ dataIndex: "qty", title: "Quantity" },
						{ dataIndex: "entry", title: "Entry ($)" },
						{ dataIndex: "take_profit", title: "Take Profit ($)" },
						{ dataIndex: "stop_loss", title: "Stop Loss ($)" },
						{ dataIndex: "profit", title: "Profit ($)" },
						{ dataIndex: "risk_reward_ratio", title: "Risk/Reward" },
					]}
				/>
			</Col>
		</Row>
	);
};

const calculateQuantity = (risk: number, entry: number, stop_loss: number) => {
	return Math.round(risk / Math.abs(entry - stop_loss));
};

const calculateTakeProfit = (
	risk_reward_ratio: number,
	entry: number,
	stop_loss: number
) => {
	const risk_per_unit = Math.abs(entry - stop_loss);

	if (entry < stop_loss) {
		return entry - risk_per_unit * risk_reward_ratio;
	}
	return entry + risk_per_unit * risk_reward_ratio;
};

const calculateProfit = (take_profit: number, entry: number, qty: number) => {
	// short position
	if (entry > take_profit) {
		return (entry - take_profit) * qty;
	}
	return (take_profit - entry) * qty;
};

export default PositionSizedCalculator;
