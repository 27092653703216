import "./App.css";
// import "../node_modules/react-grid-layout/css/styles.css";
// import "../node_modules/react-resizable/css/styles.css";

import dayjs from "dayjs";
import dayLocaleData from "dayjs/plugin/localeData";
import {
	RouteObject,
	RouterProvider,
	createBrowserRouter,
} from "react-router-dom";
import DateBlocker from "./modules/date-blocker/components/DateBlocker";
// import BaddyHelper from "./modules/baddy-helper/components/BaddyHelper";
import BaseLayout from "./modules/common/components/BaseLayout";
import Drone from "./modules/drone/components/Drone";
import Clock from "./modules/clock/components/Clock";
import Wheel from "./modules/wheel/components/Wheel";
// import Spill from "./modules/spill/components/Spill";
// import Dashboard from "./modules/dashboard/components/Dashboard";
// import SignUpPage from "./modules/signup/components/SignUpPage";
// import LogInPage from "./modules/login/components/LogInPage";
// import UserImportPage from "./modules/admin/components/UserImportPage";
import { ConfigProvider } from "antd";
import PositionSizedCalculator from "./modules/position-sized-calculator/PositionSizedCalculator";

dayjs.extend(dayLocaleData);

const App = () => {
	const routes: Array<RouteObject> = [
		{
			path: "/",
			element: <BaseLayout />,
			children: [
				{ index: true, element: <Clock /> },
				// { index: true, element: <Dashboard /> },
				// { path: "/clock", element: <Clock /> },
				{ path: "/date-blocker", element: <DateBlocker /> },
				{ path: "/wheel", element: <Wheel /> },
				// { path: "/spill", element: <Spill /> },
				// { path: "/signup", element: <SignUpPage /> },
				// { path: "/login", element: <LogInPage /> },
				// { path: "/admin", element: <UserImportPage /> },
				{ path: "/psc", element: <PositionSizedCalculator /> },
				// { path: "/baddy-helper", element: <BaddyHelper /> },
				{
					path: "/drone",
					children: [
						{
							path: ":note",
							element: <Drone />,
						},
					],
				},
			],
		},
	];
	const router = createBrowserRouter(routes);
	return (
		<ConfigProvider typography={{ style: { fontFamily: "Roboto" } }}>
			<RouterProvider router={router} />
		</ConfigProvider>
	);
};

export default App;
